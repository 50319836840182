import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'

const usePermissions = () => {
  const { permissionList } = useSelector((state: RootState) => state.permission)

  const canAccessStock = permissionList.stock.read
  const canAccessAttendu = permissionList.attendu.read
  const canAccessCommande = permissionList.commande.read
  const canAccessReference = permissionList.reference.read
  const canAccessMission = permissionList.mission.read
  const canAccessHistory = permissionList.history.read
  const canAccessClient = permissionList.client.read
  const canAccessUser = permissionList.user.read
  const canAccessCompany = permissionList.company.read
  const canAccessWarehouse = permissionList.warehouse.read
  const canAccessBilling = permissionList.billing.read
  const canAccessInventory = permissionList.inventory.read

  const canCreateAttendu = permissionList.attendu.create
  const canCreateCommande = permissionList.commande.create
  const canCreateReference = permissionList.reference.create
  const canCreateClient = permissionList.client.create
  const canCreateUser = permissionList.user.create
  const canCreateCompany = permissionList.company.create

  const canUpdateStock = permissionList.stock.update
  const canUpdateAttendu = permissionList.attendu.update
  const canUpdateCommande = permissionList.commande.update
  const canUpdateReference = permissionList.reference.update
  const canUpsertStockMission = permissionList.mission.upsert_stock
  const canUpsertAttenduMission = permissionList.mission.upsert_attendu
  const canUpsertCommandeMission = permissionList.mission.upsert_commande
  const canUpdateClient = permissionList.client.update
  const canUpdateUser = permissionList.user.update
  const canUpdateCompany = permissionList.company.update

  const canDeleteReference = permissionList.reference.delete
  const canDeleteAttendu = permissionList.attendu.delete
  const canDeleteCommande = permissionList.commande.delete
  const canDeleteStockMission = permissionList.mission.delete_stock
  const canDeleteAttenduMission = permissionList.mission.delete_attendu
  const canDeleteCommandeMission = permissionList.mission.delete_commande
  const canDeleteClient = permissionList.client.delete
  const canDeleteUser = permissionList.user.delete
  const canDeleteCompany = permissionList.company.delete

  const canImportReference = permissionList.reference.import
  const canImportAttendu = permissionList.attendu.import
  const canImportCommande = permissionList.commande.import

  const canExportStock = permissionList.stock.export
  const canExportAttendu = permissionList.attendu.export
  const canExportCommande = permissionList.commande.export
  const canExportReference = permissionList.reference.export
  const canExportClient = permissionList.client.export
  const canExportUser = permissionList.reference.export
  const canExportCompany = permissionList.user.export

  return {
    canAccessStock,
    canAccessAttendu,
    canAccessCommande,
    canAccessReference,
    canAccessMission,
    canAccessHistory,
    canAccessClient,
    canAccessUser,
    canAccessCompany,
    canAccessWarehouse,
    canAccessBilling,
    canAccessInventory,
    canCreateReference,
    canCreateAttendu,
    canCreateCommande,
    canCreateClient,
    canCreateUser,
    canCreateCompany,
    canUpdateStock,
    canUpdateAttendu,
    canUpdateCommande,
    canUpdateReference,
    canUpsertStockMission,
    canUpsertAttenduMission,
    canUpsertCommandeMission,
    canUpdateClient,
    canUpdateUser,
    canUpdateCompany,
    canDeleteReference,
    canDeleteAttendu,
    canDeleteCommande,
    canDeleteStockMission,
    canDeleteAttenduMission,
    canDeleteCommandeMission,
    canDeleteClient,
    canDeleteUser,
    canDeleteCompany,
    canImportReference,
    canImportAttendu,
    canImportCommande,
    canExportStock,
    canExportAttendu,
    canExportCommande,
    canExportReference,
    canExportClient,
    canExportUser,
    canExportCompany,
  }
}

export default usePermissions
