import {
  AllEntrepotToUpdateSociete,
  EntrepotInterface,
  Societe,
  SocieteArrayInterface,
  SocieteCreateResponseInterface,
  SocieteGetByIdResponseInterface,
  TransfertsMailArrayInterface,
  TransfertsMailDefaultResponse,
  AuthenticationPayload,
  TransfertsMailPayload
} from '../models'
import baseAxios from './clientAPI'

const societeApi = {
  getAllEntrepot(payload: {
    limit: Number
    offset: Number
  }): Promise<EntrepotInterface> {
    const url = `/warehouse/warehouse-company?limit=${payload?.limit}&offset=${payload?.offset}`
    return baseAxios.get(url)
  },

  getAllSociete(payload: {
    limit: Number
    offset: Number
  }): Promise<SocieteArrayInterface> {
    const url = `/warehouse/company?limit=${payload.limit}&offset=${payload.offset}&order=DESC`
    return baseAxios.get(url)
  },

  createSociete(payload: Societe): Promise<SocieteCreateResponseInterface> {
    const url = '/warehouse/company'
    return baseAxios.post(url, payload)
  },

  getSocieteById(payload: {
    id: string
  }): Promise<SocieteGetByIdResponseInterface> {
    const url = `warehouse/company/id/${payload.id}`
    return baseAxios.get(url)
  },

  getAllEntrepotToUpdateSociete(payload: {
    id: string
  }): Promise<AllEntrepotToUpdateSociete> {
    const url = `warehouse/company/warehouse/${payload.id}`
    return baseAxios.get(url)
  },

  deleteSocieteById(payload: { id: string }): Promise<any> {
    const url = `warehouse/company/${payload.id}`
    return baseAxios.delete(url)
  },

  deleteMultipleSociete(payload: { ids: string[] }): Promise<any> {
    const url = `warehouse/company/many`
    return baseAxios.delete(url, { data: { ids: payload.ids } })
  },

  editSociete(payload: Societe): Promise<SocieteGetByIdResponseInterface> {
    const url = 'warehouse/company/update'
    return baseAxios.post(url, payload)
  },
  
  getTransfersMailSociete(payload: { id: string }): Promise<TransfertsMailArrayInterface> {
    const url = `warehouse/maildatatransfer/company/${payload.id}`
    return baseAxios.get(url)
  },
  
  createTransfersMailSociete(payload: { id: string }) {
    const url = `warehouse/maildatatransfer/company/${payload.id}`
    return baseAxios.post(url)
  },
  
  updateTransfersMailSociete(payload: TransfertsMailPayload): Promise<TransfertsMailDefaultResponse> {
    const url = `warehouse/maildatatransfer/${payload.config_id}`
    return baseAxios.put(url, payload)
  },
  
  deleteTransfersMailSociete(configId: string): Promise<TransfertsMailDefaultResponse> {
    const url = `warehouse/maildatatransfer/${configId}`
    return baseAxios.delete(url)
  },
  
  checkMailServerAuthentication(payload: AuthenticationPayload): Promise<TransfertsMailDefaultResponse> {
    const url = `email/testing/authentication`
    return baseAxios.post(url, payload)
  }
}

export default societeApi
