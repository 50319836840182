import React, { useEffect, useState } from 'react'
import Define from '../../constants/define';
import { UserRole } from '../../enum/UserRole';
import { collection, doc, DocumentData, onSnapshot, query, Query, Unsubscribe, where } from 'firebase/firestore';
import { db } from '../../myFirebase/firebase-config';
import { quartetToDocId, triangleToQuartet } from './func';
import authApi from '../../http/authApi';
import { NotificationBell, Quartet } from '../../models/Notification';
import { cloneDeep } from 'lodash';


export default function useNotificationBell() {
  const [fetchedQuartets, setFetchedQuartets] = useState(false);
  // the combine of user id, client id, warehouse id and company id to generate a uuid in firestore
  const [quartets, setQuartets] = useState<Quartet[]>([])
  const [count, setCounts] = useState<number>();
  const [notificationBells, setNotificationBells] = useState<Map<string, NotificationBell>>(new Map());
  useEffect(() => {
    if(!fetchedQuartets) return;
    const role = localStorage.getItem(Define.ROLE);
    if(!role || role === UserRole.SUPERADMIN) return;

    let unsubscribe = () => { };
    const notiQuery = query(
      collection(db, "notis"),
      where("receiver_id", "==", localStorage.getItem(Define.USER_ID))
    )
    unsubscribe = onSnapshot(notiQuery, (snapshot) => {
      const docs = snapshot.docs;
      const notificationBellsClone: Map<string, NotificationBell> = cloneDeep(notificationBells)
      const notiChange: Map<string, number> = new Map();

      //get all init notif
      docs.forEach(doc => {
        const data = doc.data() as NotificationBell;
        const oldData = notificationBellsClone.get(doc.id);
        data.client_code_nom = oldData?.client_code_nom;
        data.company_code_nom = oldData?.company_code_nom;
        data.warehouse_code_nom = oldData?.warehouse_code_nom;
        notificationBellsClone.set(doc.id, data);
      })
      //handle when noti change
      snapshot.docChanges().forEach(change =>{
        const doc = change.doc;
        notiChange.set(doc.id, (doc.data() as NotificationBell).count)
      })

      notiChange.forEach((value, key)=>{
        const itsNoti = notificationBellsClone.get(key);
        if(!itsNoti) return;
        itsNoti.count = value;
        notificationBellsClone.set(key, itsNoti);
      })
      setNotificationBells(notificationBellsClone);
    })

    return () => {
      unsubscribe();
    };

  }, [fetchedQuartets, quartets])

  useEffect(() => {
    if (!notificationBells) return;
    if (notificationBells?.size === 0) return;
    let sum = 0;
    notificationBells.forEach((value) => {
      sum += value.count;
    })
    setCounts(sum);
  }, [notificationBells])

  useEffect(() => {
    const role = localStorage.getItem(Define.ROLE);
    if (!role || role === UserRole.SUPERADMIN) return;

    const notificationBellsClone = cloneDeep(notificationBells);

    authApi.getMe().then(res => {
      const triangles = res.data.entry.client_warehouse_company;
      const quartets = triangleToQuartet(triangles);
      quartets.forEach(quartet => {
        notificationBellsClone.set(quartetToDocId(quartet), {
          count: 0,
          receiver_id: quartet.user_id,
          ...quartet
        })
      })
      setNotificationBells(notificationBellsClone)
      setQuartets(quartets)
    })
      .catch(err => console.log("getMeErr: ", err))
      .finally(() => {
        setFetchedQuartets(true);
      })
  }, [])


  return {
    quartets,
    count,
    notificationBells
  }

}


