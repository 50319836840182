import { Avatar, Badge, Tag, Tooltip } from 'antd'
import React from 'react'
import BELL_ICON from '../../assets/icons/noti/bell.svg'
import { Link } from 'react-router-dom'
import useNotificationBell from './useNotificationBell'
import { NotificationBell } from '../../models/Notification'

export default function NotiBell() {
  const { count, notificationBells } = useNotificationBell()
  
  function genToolTip() {
    const tooltips: NotificationBell[] = [];
    notificationBells.forEach(value => {
      if (value.count === 0) return;
      tooltips.push(value);
    })

    return tooltips.map(tooltip => {
      const {
        client_code_nom = '',
        company_code_nom = '',
        warehouse_code_nom = '',
        count = 0
      } = tooltip;
      const content = `${client_code_nom}-${warehouse_code_nom}-${company_code_nom}: ${count}`
      return <div>{content}</div>
    })
  }
  return (
    <Tooltip placement='top' title={genToolTip()} >
      <Link to={'notifications'}>
        <Badge count={count} size={"small"}>
          <img src={BELL_ICON} className="cursor-pointer" alt='noti' />
        </Badge>
      </Link>

    </Tooltip>
  )
}
