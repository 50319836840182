import { cloneDeep, forEach, has, set } from 'lodash'
import { PermissionAction } from '../models/Permission'
import { initPermissionList } from '../redux/reducers/permissionSlice'
import { store } from '../app/store'
import { UserRole } from '../enum/UserRole'

export const isSuperAdmin = () => {
  const { permission } = store.getState()
  return permission.role === UserRole.SUPERADMIN
}

export const mapPermissionList = (permissions?: PermissionAction[]) => {
  const permissionList = cloneDeep(initPermissionList)
  if (!isSuperAdmin()) {
    // Reset all permissions to false
    forEach(permissionList, (permissions) => {
      forEach(permissions, (value, key) => {
        set(permissions, key, false)
      })
    })
    // Set permissions of the user to true
    if (permissions) {
      forEach(permissions, ({ action, entity }) => {
        if (has(permissionList, [entity, action])) {
          set(permissionList, [entity, action], true)
        }
      })
    }
  }
  return permissionList
}

export const isPermissionEnabled = () =>
  process.env.REACT_APP_ENV === '"STAGING"'
