import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusAPI } from '../../enum'
import { NewStock, StockType, TotalAccessoriesApi } from '../../models'
import { Metadata } from '../../models/common/common'

export interface GetAllPayload {
  limit: Number
  offset: Number
}

interface currentStockList {
  status: StatusAPI
  stockList: NewStock[]
  loading: boolean
  metadata: Metadata
}

interface totalStockList {
  status: StatusAPI
  number: number
  loading: boolean
}

export interface stockState {
  currentListStock: currentStockList
  error: string
  totalListStock: totalStockList
}

const initialState: stockState = {
  currentListStock: {
    status: StatusAPI.initial,
    stockList: [],
    loading: false,
    metadata: {
      count: 0,
      limit: 0,
      total: 0,
    },
  },
  totalListStock: {
    status: StatusAPI.initial,
    number: 0,
    loading: false,
  },
  error: '',
}

const stockSlice = createSlice({
  name: 'Stock',
  initialState,
  reducers: {
    clearList(state) {
      state.currentListStock = initialState.currentListStock
    },
    clearTotalNumber(state) {
      state.currentListStock = initialState.currentListStock
    },
    getAllStock(state, action: PayloadAction<{ isRealTime: boolean }>) {
      state.currentListStock.status = StatusAPI.calling
      state.currentListStock.loading = !action.payload.isRealTime
    },
    getAllStockSuccess(state, action: PayloadAction<any>) {
      state.currentListStock.status = StatusAPI.success
      state.currentListStock.stockList = action.payload.data.entry;
      if(state.currentListStock.stockList){
        state.currentListStock.stockList.forEach(item =>{
          item.blocage_entree = false;
          if(!item.pre_block_status) return;
          const pre_block_status = JSON.parse(item.pre_block_status);
          if(!pre_block_status?.blocage_entree) return;
          pre_block_status.blocage_entree = false;
          item.pre_block_status = JSON.stringify(pre_block_status);
        })
      }

      state.currentListStock.loading = false
      state.currentListStock.metadata = action.payload.data.metadata
    },
    getAllStockFailed(state, action: PayloadAction<any>) {
      state.currentListStock.status = StatusAPI.failure
      state.error = action.payload
      state.currentListStock.loading = false
    },
    // get All stock without filter
    getTotalStock(state, action: PayloadAction) {
      state.totalListStock.status = StatusAPI.calling
      state.totalListStock.loading = true
    },
    getTotalStockSuccess(state, action: PayloadAction<TotalAccessoriesApi>) {
      state.totalListStock.status = StatusAPI.success
      state.totalListStock.number = action.payload.data.total
      state.totalListStock.loading = false
    },
    getTotalStockFailed(state, action: PayloadAction<any>) {
      state.totalListStock.status = StatusAPI.failure
      state.error = action.payload
      state.totalListStock.loading = false
    },
    addDetailStock(state, action: PayloadAction<any>) {
      state.currentListStock.stockList = [
        ...state.currentListStock.stockList,
      ].map((item) => {
        if (item.id === action.payload.stockParentId) {
          return {
            ...item,
            stock: [
              ...(item.stock || []),
              ...action.payload.newItems.filter(
                (i: StockType) =>
                  !item.stock?.find((currentItem) => currentItem.id === i.id)
              ),
            ],
            total: action.payload.meta.total,
          }
        } else {
          return item
        }
      })
    },
    // update main line stock when reference is updated
    updateReferenceStock(state, action: PayloadAction<NewStock>) {
      if(state?.currentListStock?.stockList){
        const referenceUpdatedIndex = state.currentListStock.stockList.findIndex(
          (item) => item.id === action.payload.id
        )
        if (referenceUpdatedIndex >= 0) {
          // @ts-ignore
          delete action.payload.stock
          state.currentListStock.stockList[referenceUpdatedIndex] = {
            ...state.currentListStock.stockList[referenceUpdatedIndex],
            ...action.payload,
          }
        }
      }

    },
  },
})

const stockReducer = stockSlice.reducer
export default stockReducer

export const {
  clearList,
  getAllStock,
  getAllStockFailed,
  getAllStockSuccess,
  clearTotalNumber,
  getTotalStock,
  getTotalStockFailed,
  getTotalStockSuccess,
  updateReferenceStock,
} = stockSlice.actions
