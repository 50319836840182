import { notification } from 'antd'
import Error from '../assets/images/error.png'
import CloseIcon from '../assets/images/iconClose.png'
import { t } from 'i18next'

// This function checks if a notification with the given text is displaying
function isNotificationWithTextDisplayed(text: string) {
  const elements = document.querySelectorAll(`.ant-notification-notice-message`)
  for (let element of elements) {
    if (element.textContent?.includes(text)) {
      return true
    }
  }
  return false
}

// Prevent showing multiple error notifications at the same time
let lastNotificationTime = 0

export const showErrorNotification = (message?: string) => {
  const currentTime = Date.now()
  if (currentTime - lastNotificationTime >= 100) {
    if (!isNotificationWithTextDisplayed(message || t('errorHasOccurred'))) {
      notification.open({
        className: 'noti noti-error',
        message: (
          <div className="flex items-center">
            <img
              src={Error}
              alt="error"
              width={50}
              style={{ marginRight: 10 }}
            />
            {message || t('errorHasOccurred')}
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CloseIcon} alt="close" />,
        duration: 5,
      })
      lastNotificationTime = currentTime
    }
  }
}

export const showPermissionError = () => {
  showErrorNotification(t('noPermissionError'))
}
